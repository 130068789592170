<template>
  <div class="h-full w-screen">
    <PageSection
      reduced
      class="h-64"
    >
      <SectionText>
        We offer 1-1 personal training and small group sessions to <span class="text-primary font-normal">high performing, elite level or professional athletes</span> looking to improve at their chosen sport.
      </SectionText>
      <SectionText>
        Our coaches have shared experience and a wealth of knowledge in <span class="text-primary font-normal">high performance coaching</span> and provide a personal, human based approach to <span class="text-primary font-normal">all athletes looking to improve.</span>
      </SectionText>
      <SectionText>
        If you would like to be involved, reach out to us below.
      </SectionText>
      <button
        class="w-auto mt-4"
        @click="
          toggleModal({
            modalName: 'contact',
            subject: $route.meta.title,
            show: true,
          })
        "
      >
        <div
          class="bg-primary font-semibold text-black uppercase text-xs rounded-xl p-2 px-4"
          :aria-label="`C Contact us about high performance athletic coaching `"
        >
          Contact us about high performance athletic coaching 
        </div>
      </button>
    </PageSection>
    <LoadingState v-if="getTestimonialsIsLoading" />
    <ErrorState
      v-else-if="getTestimonialsIsError"
      :error="getTestimonialsError"
      :retry="fetchTestimonials"
    />

    <PageSection
      v-else
      title="Athlete testimonials"
      clas="flex flex-col "
    >
      <div
        :class="[
          'grid grid-cols-6 gap-12 pt-8 ',
        ]"
      >
        <div
          v-for="(athlete, index) in getTestimonials"
          :key="index"
          class="md:col-span-3 lg:col-span-3 col-span-6 flex flex-grow"
        >
          <Card
            bordered
            :background="'bg-opacity-75 bg-secondary-200'"
          >
            <template v-slot:subtitle>
              <p
                class="text-primary font-base leading-normal text-center"
                :class="[
                  'text-base',
                ]"
              >
                {{ athlete.athlete }}
              </p>
            </template>
            <template v-slot:title>
              <div
                class="flex flex-col gap-y-2 items-center justify-center mb-6"
              >
                <Avatar
                  :source="athlete.image.asset.url"
                  :text="athlete.athlete"
                  class="bg-secondary-200 my-4 w-32 h-32 object-cover"
                />
                <p class="text-secondary-200 text-xs text-center">
                  {{ athlete.sport }}
                </p>

                <div
                  v-if="athlete.achievements"
                >
                  <p
                    v-for="achievement in athlete.achievements"
                    :key="achievement"
                    class="italic text-primary text-xs text-center"
                  >
                    {{ achievement }}
                  </p>
                </div>
              </div>
            </template>
            <template v-slot:body>
              <div class="border-l pl-4 border-secondary-DEFAULT">
                <BlogBlocks :content="athlete.comment" />
              </div>
            </template>
          </Card>
        </div>
      </div>
    </PageSection>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { ErrorState, LoadingState, PageSection, SectionText, Card } from '@/pages/shared/index';
import testimonialStore from '@/infrastructure/store/modules/testimonials/';
import Avatar from '@/components/atoms/Avatar/Avatar.vue';
import BlogBlocks from '@/components/organisms/BlogBlocks/BlogBlocks.vue';

export default {
  name: 'AthletesPage',
  components: {
    ErrorState,
    LoadingState,
    PageSection,
    SectionText,
    Card,
    Avatar,
    BlogBlocks
  },
  computed: {
    ...mapGetters('testimonial', [ 'getTestimonials', 'getTestimonialsIsError','getTestimonialsError', 'getTestimonialsIsLoading'  ])
  },
  async created() {
    if(!this.$store.hasModule('testimonial')) this.$store.registerModule('testimonial', testimonialStore);
    await this.fetchTestimonials('athletes');
  },
  methods: {
    ...mapActions([ 'toggleModal' ]),
    ...mapActions('testimonial', [ 'fetchTestimonials' ])
  }
};
</script>
